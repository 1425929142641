/* eslint-disable @typescript-eslint/no-unused-vars */

import { createApp } from 'vue';
import adapter from 'webrtc-adapter';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from '@jcc/App.vue';
import apiService from '@jcc/apiService';
import router from './router';
import setupI18n from './i18n';
import type { InitOptions } from './i18n';
import setupIcons from './icons';


(async () => {
  const app = createApp(App);
  app.component('QRCode', VueQrcode);
  apiService.setRouter(router);
  app.use(router);
  setupIcons(app);
  let initialLocale = navigator.languages[0].replace(/-[A-Z]./, '') as InitOptions['locale'];
  if (initialLocale !== 'ru') {
    initialLocale = 'en';
  }
  document.querySelector('#app')?.setAttribute('lang', initialLocale);
  await setupI18n(app, { locale: initialLocale });
  app.mount('#app');
})();
