import { createI18n } from 'vue-i18n';
import type { App } from 'vue';


type InitOptions = {
  locale: 'ru' | 'en';
};


export default async (app: App, { locale }: InitOptions): Promise<void> => {
  const { default: messages } = await import(
    /* webpackChunkName: "localeMessages" */
    `@jcc/locales/${locale}.json`
  );
  app.use(
    createI18n({
      locale,
      fallbackLocale: 'en',
      messages: {
        [locale]: messages,
      },
    })
  );
};
export type { InitOptions };
