import { defineComponent, h } from 'vue';
import type { App } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMicrophoneAlt } from '@fortawesome/free-solid-svg-icons/faMicrophoneAlt';
import { faMicrophoneAltSlash } from '@fortawesome/free-solid-svg-icons/faMicrophoneAltSlash';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons/faVideoSlash';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


enum EIcons {
  MIC_ON = 'micOn',
  MIC_OFF = 'micOff',
  CAMERA_ON = 'cameraOn',
  CAMERA_OFF = 'cameraOff',
  WARNING = 'warning',
}
const ICONS_ADAPTERS = {
  [EIcons.MIC_ON]: 'microphone-alt',
  [EIcons.MIC_OFF]: 'microphone-alt-slash',
  [EIcons.CAMERA_ON]: 'video',
  [EIcons.CAMERA_OFF]: 'video-slash',
  [EIcons.WARNING]: 'exclamation-triangle',
};
const REGULAR_ICONS: string[] = [];
const Icon = defineComponent({
  components: { FontAwesomeIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    nameAttr: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  computed: {
    iconName(): string {
      return ICONS_ADAPTERS[this.name as keyof typeof ICONS_ADAPTERS] || this.name;
    },
    iconType(): string {
      return REGULAR_ICONS.includes(this.name) ? 'far' : 'fas';
    },
  },
  render() {
    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const self = this;
    const attributes: { class: string, name?: string } = {
      class: 'pointerCursor',
    };
    if (this.nameAttr) {
      attributes.name = this.nameAttr;
    }
    return h(
      'div',
      attributes,
      h(FontAwesomeIcon, {
        class: 'pointerCursor',
        icon: [this.iconType, this.iconName],
        onClick($event: Event) {
          $event.stopPropagation();
          self.$emit('click');
        },
      })
    );
  },
});
export default (app: App): void => {
  [
    faMicrophoneAlt, faMicrophoneAltSlash, faVideo, faVideoSlash,
    faExclamationTriangle,
  ].forEach((icon) => {
    library.add(icon);
  });
  app.component('Icon', Icon);
};
export { Icon, EIcons };
