
import 'reflect-metadata';
import { Options, Vue } from 'vue-property-decorator';
import { isMobileOrTablet } from '@jcc/tools/utils';
import { EFrameMessages } from '@jcc/tools/constants';
import type { FrameMessage } from '@jcc/tools/interfaces';


@Options({
  computed: {
    isDesktop: () => !isMobileOrTablet(),
  },
})
export default class App extends Vue {
  isFrameMode = false;

  mounted(): void {
    window.addEventListener('message', this.processMessage, false);
  }

  processMessage(message: FrameMessage): void {
    if (message.data.type === EFrameMessages.SET_FRAME_MODE) {
      this.isFrameMode = true;
      document.body.setAttribute('style', 'overflow-y: hidden;');
    }
  }
}
