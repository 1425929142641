import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["App", {    'App--mode_frame': _ctx.isFrameMode,    'App--mobileTablet': !_ctx.isDesktop,  }])
  }, [
    _createVNode(_component_router_view)
  ], 2 /* CLASS */))
}