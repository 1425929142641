import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import * as Sentry from '@sentry/vue';
import CONFIG from '@jcc/config';
import apiService from '@jcc/apiService';
import { delay } from '@jcc/tools/utils';


const { PATHS, PATHS_NAMES } = CONFIG;
const SERVER_CONNECTED_PATHS = [
  PATHS_NAMES.connectingProvider,
  PATHS_NAMES.editorViewer,
  PATHS_NAMES.studioViewer,
];
const routes = [
  {
    path: PATHS.main,
    name: PATHS_NAMES.main,
    redirect: PATHS.connectingProvider,
  },
  {
    path: `${PATHS.connectingProvider}/:error?`,
    name: PATHS_NAMES.connectingProvider,
    component: () => import(
      /* webpackChunkName: "ConnectingProvider" */
      '@jcc/components/ConnectingProvider.vue'
    ),
    props: true,
  },
  {
    path: `${PATHS.connectedProvider}/:password/:roomId?`,
    name: PATHS_NAMES.connectedProvider,
    component: () => import(
      /* webpackChunkName: "ConnectedProvider" */
      '@jcc/components/ConnectedProvider.vue'
    ),
    props: true,
    async beforeEnter(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      let nextParams!: any;
      try {
        if (!to.params.roomId) {
          const res = await apiService.joinRoom((to.params as { password: string }).password);
          if (res.room_id) {
            nextParams = {
              name: PATHS_NAMES.connectedProvider,
              params: {
                ...to.params,
                roomId: res.room_id,
              },
            };
          } else {
            nextParams = {
              name: PATHS_NAMES.noConnection,
              params: { fromPath: to.fullPath },
            }
          }
        }
      } catch (e) {
        Sentry.captureMessage(`Ошибка присоединения к комнате с паролем [${to.params.password}]`);
        Sentry.captureException(e);
        console.error(e);
        nextParams = {
          name: PATHS_NAMES.noConnection,
          params: { fromPath: to.fullPath },
        };
      } finally {
        await delay(1_500);
        next(nextParams);
      }
    },
  },
  {
    path: `${PATHS.editorViewer}/:roomId`,
    name: PATHS_NAMES.editorViewer,
    component: () => import(
      /* webpackChunkName: "EditorViewer" */
      '@jcc/components/EditorViewer.vue'
    ),
    props: true,
  },
  {
    path: `${PATHS.studioViewer}/:roomId`,
    name: PATHS_NAMES.studioViewer,
    component: () => import(
      /* webpackChunkName: "StudioViewer" */
      '@jcc/components/StudioViewer.vue'
    ),
    props: true,
  },
  {
    path: `${PATHS.noConnection}/:fromPath?`,
    name: PATHS_NAMES.noConnection,
    component: () => import(
      /* webpackChunkName: "NoConnection" */
      '@jcc/components/NoConnection.vue'
    ),
    props: true,
    beforeEnter(
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext
    ) {
      if (!SERVER_CONNECTED_PATHS.includes((from as { name: string }).name)) {
        // Попытка зайти в noConnection напрямую
        next({ name: PATHS_NAMES.main });
      } else if (from.name === PATHS_NAMES.connectingProvider) {
        // Переход из попытки зайти в Connected Provider (предварительно проверяется связь)
        next();
      } else {
        // Переходы из Viewer
        next({
          name: PATHS_NAMES.noConnection,
          params: { fromPath: from.fullPath },
        });
      }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: PATHS.connectingProvider,
  },
];
const router = createRouter({
  history: IS_DEV ? createWebHashHistory() : createWebHistory(),
  routes,
});
router.replace({ name: PATHS_NAMES.connectingProvider });

export default router;
